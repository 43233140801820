.app {
  display: grid;
  grid-template-columns: 11.3rem repeat(8, 1fr) 11.3rem;
  gap: 2.4rem;
  position: relative;

  @include respond(big-desktop) {
    max-width: 120rem;
    margin: 0 auto;
  }

  @include respond(phone) {
    grid-template-columns: 6.4rem 1fr 2.5rem;
    grid-template-rows: repeat(3, min-content);
  }

  @include respond(md-phone) {
    grid-template-columns: 1.6rem 1fr 1.6rem;
    gap: 1.6rem;
  }

  @include respond(sm-phone) {
    grid-template-columns: 1.2rem 1fr 1.2rem;
    gap: 1.2rem;
  }

  &__navigation {
    grid-column: 1 / 2;
    align-self: start;
    // justify-self: center;

    @include flex(space-between, center);
    flex-direction: column;
    gap: 4.8rem;
    @include position(fixed, 4.8rem, 3.2rem);

    @include respond(big-desktop) {
      @include position(fixed, 4.8rem, initial);
      margin-left: 3.7rem;
    }

    @include respond(height) {
      @include position(fixed, 50%, 3.2rem);
      transform: translateY(-50%);
    }

    @include respond(phone) {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      width: 100%;

      flex-direction: row;
      @include position(fixed, 0, 0);
      padding: 3.2rem 2.4rem 0;
      margin-left: 0;
      z-index: 2;
    }
  }

  &__home,
  &__portfolio,
  &__blog {
    grid-column: 3 / -2;

    @include respond(sm-laptop) {
      grid-column: 3 / -1;
    }

    @include respond(tablet) {
      grid-column: 2 / -1;
    }

    @include respond(md-phone) {
      grid-column: 2 / -2;
    }
  }

  &__about {
    grid-column: 3 / -2;

    @include respond(sm-laptop) {
      grid-column: 3 / -1;
    }

    @include respond(tablet) {
      grid-column: 2 / -1;
    }
  }

  &__footer {
    grid-column: 3 / -2;

    @include respond(sm-laptop) {
      grid-column: 3 / -1;
      margin-right: 2.4rem;
    }

    @include respond(tablet) {
      grid-column: 2 / -1;
    }
  }
}
