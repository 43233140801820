*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
  user-select: none;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  line-height: 1.6;
  background-color: #171717;
  color: #ffffff;
}

a:link,
a:visited {
  text-decoration: none;
}

img {
  max-width: 100%;
}

[class^="section-"] {
  height: 100vh;
  position: relative;
}

[class$="__content-box"] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
