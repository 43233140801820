.navigation {
  &__list {
    list-style: none;

    @include respond(phone) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 4.8rem;
    }
  }

  &__logo {
    position: relative;
    z-index: 20;

    @include respond(phone) {
      align-self: flex-start;
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    gap: 4.8rem;

    @include respond(phone) {
      opacity: 1;
      // visibility: visible;
      // pointer-events: none;

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;

      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #171717;
      transform: translateX(0);
      transition: transform 0.3s;
    }

    &--hidden {
      // display: none;
      @include respond(phone) {
        transform: translateX(100%);
      }
    }
  }

  &__item {
    writing-mode: vertical-lr;
    transform: rotate(-180deg);
    cursor: pointer;

    @include respond(phone) {
      writing-mode: initial;
      transform: rotate(0);
    }

    &:not(:first-child) {
      margin-top: 4.8rem;

      @include respond(phone) {
        margin-top: 0;
      }
    }

    a:link,
    a:visited {
      color: #ffffff;
    }
  }

  &__social-icons {
    @include flex(space-between, center);
    flex-direction: column;
    gap: 1.2rem;
    margin-top: 3.2rem;

    @include respond(phone) {
      flex-direction: row;
    }
  }

  &__mobile-btn {
    display: none;

    @include respond(phone) {
      display: block;
      position: relative;
    }

    .menu {
      background-color: #171717;
      border: 2px solid transparent;
      border-radius: 0.4rem;
    }
  }
}
