.heading {
  &-main,
  &-primary {
    font-size: 11.4rem;
    // font-size: clamp(5.2rem, 11.4vw, 11.4rem);
    font-weight: 500;
    line-height: 105%;

    @include respond(tablet) {
      font-size: 9.8rem;
    }

    @include respond(phone) {
      font-size: 8rem;
    }

    @include respond(md-phone) {
      font-size: 6.4rem;
    }

    @include respond(sm-phone) {
      font-size: 5.2rem;
    }
  }

  &-main {
    span {
      display: block;
    }
  }

  &-caption {
    font-size: 2.4rem;
    margin-bottom: 0.8rem;

    @include respond(tablet) {
      font-size: 2rem;
    }

    @include respond(phone) {
      font-size: 1.8rem;
    }
  }

  &-main-2 {
    font-size: 8rem;
    font-weight: 500;
    line-height: 114%;

    @include respond(tablet) {
      font-size: 7.2rem;
    }

    @include respond(phone) {
      font-size: 5.6rem;
    }

    @include respond(md-phone) {
      font-size: 4.8rem;
    }

    @include respond(sm-phone) {
      font-size: 3.2rem;
    }
  }
}

.description {
  &-primary {
    max-width: 60rem;
    font-size: 2.4rem;

    @include respond(tablet) {
      max-width: 50rem;
      font-size: 2rem;
    }

    @include respond(phone) {
      max-width: 40rem;
      font-size: 1.6rem;
    }
  }
}
