.btn {
  font-size: inherit;
  font-family: inherit;
  // font-weight: 500;
  color: inherit;
  background: none;
  border: none;
  cursor: pointer;

  &--text-icon {
    @include flex(center, center);
    gap: 2.4rem;

    padding: 1.2rem 0;

    span {
      @include respond(md-phone) {
        display: none;
      }
    }

    img {
      display: block;
    }
  }
}
