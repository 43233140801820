.blog {
  &__header {
    @extend .portfolio__header;

    display: flex;
    align-items: center;

    @include respond(phone) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }

  &__header-text {
    margin-right: 4.8rem;

    @include respond(phone) {
      margin-right: 0;
    }

    & > *:not(:last-child) {
      margin-bottom: 2.4rem;
    }

    h3 {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }

    p {
      max-width: 100%;
      font-weight: 500;
      line-height: 2.4rem;
    }
  }

  &__header-avatar {
    max-width: 13.2rem;
    flex-shrink: 0;

    @include respond(phone) {
      margin-bottom: 2.4rem;
    }

    img {
      max-width: 100%;
    }
  }

  &__posts {
    @extend .portfolio__projects;
    margin: 4.8rem 0;
  }

  &__post-grid {
    @extend .portfolio__project-grid;
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  }

  &__post-card {
    & > *:not(:last-child) {
      margin-bottom: 1.2rem;
    }

    h2 {
      font-size: 3.2rem;
      line-height: 4rem;
    }
  }

  &__datetime {
    display: flex;

    & > * {
      color: #a3a3a3;

      &:not(:last-child) {
        margin-right: 1.2rem;
      }
    }
  }
}

.newsletter {
  &__section {
    margin-bottom: 9.6rem;

    @include respond(phone) {
      margin-bottom: 18.6rem;
    }

    form {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > *:not(:last-child) {
        margin-right: 1.2rem;

        @include respond(phone) {
          margin-right: 0;
          margin-bottom: 2.4rem;
          width: 100%;
        }
      }

      @include respond(phone) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    p {
      max-width: 25.4rem;
    }

    input {
      font-size: 1.6rem;
      font-weight: 500;
      font-family: inherit;
      flex: 1;
      padding: 1.4rem 1.6rem;
      border: 1.3px solid hsla(0, 0%, 27%, 1);
      border-radius: 0.4rem;
      background: none;
      color: #ffffff;
      // transition: all 0.2s;

      &::placeholder {
        color: #ffffff;
      }
    }
  }

  &__btn {
    font-size: 1.6rem;
    font-weight: 500;
    font-family: inherit;
    background-color: #ffffff;
    color: hsla(0, 0%, 27%, 1);
    border: none;
    padding: 1.4rem;
    border-radius: 0.4rem;
    cursor: pointer;

    @include respond(phone) {
      width: 100%;
    }
  }
}
